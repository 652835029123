@import "../../styles/monokai.scss";
@import "../../styles/variables.scss";

.header {
  background-color: $mk-bg-dark;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 7vh;

  &:hover {
    background-color: $mk-bg-darkish;
    cursor: pointer;
  }
}

@include media("<=phone") {
  .header {
    height: 16vh;
  }
}

@include media(">=phone", "<tablet") {
  .header {
    height: 16vh;
  }
}

@include media(">=tablet", "<=desktop") {
  .header {
    height: 12vh;
  }
}
