@import "../../styles/monokai.scss";

.footer {
  background-color: $mk-bg;
  position: fixed;
  z-index: 1;
  width: 100%;
  bottom: 0px;
  padding: 0;
  text-align: center;
  height: 25px;

  &:hover {
    background-color: $mk-bg-light;
    cursor: pointer;
  }
}
